import {useContext, useEffect, useState} from "react";
import PropTypes from 'prop-types';
import Tooltip from "@components/ui/tooltip";
import {WishlistContext} from "@global/WishlistContext";
import {toast} from "react-toastify";

const WishlistProductAction = ({product}) => {
    const [windowSize, setWindowSize] = useState(0);
    const {removeFromWishlist} = useContext(WishlistContext);

    useEffect(() => {
        setWindowSize(window.innerWidth);
    }, [windowSize]);

    return (
        <Tooltip
            content="Remove From Wishlist"
            position={windowSize <= 1024 ? 'top' : 'left'}
            className="tt-btn-rmv-wishlist active"
        >
            <span onClick={() => {
                removeFromWishlist(product);
                toast.warning(`"${product.name}" fue removido de Favoritos PA.`, {
                    position: "bottom-right",
                    autoClose: 3000,
                })
            }}/>
        </Tooltip>
    );
};

WishlistProductAction.propTypes = {
    product: PropTypes.object.isRequired
};

export default WishlistProductAction;